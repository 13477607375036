import { useState } from 'react';
import { Box, Text, Spacer } from '@flexisaf/flexibull2';

import LOGO from '../../assets/logo.svg';
import { Theme } from '../../config/theme';
import { lighten } from 'polished';
import Step1 from './step1';
import Step2 from './step2';

const TabTitle = ({ active, index, title, onClick }) => {
  return (
    <Text
      style={{ cursor: 'pointer' }}
      bold={active}
      color={active ? Theme.PrimaryColor : Theme.PrimaryFontColor}
      onClick={() => onClick()}
    >
      <span
        style={{
          border: `0.5px solid ${active ? Theme.PrimaryColor : Theme.PrimaryBorderColor}`,
          margin: '0 10px 0  0',
          padding: '5px 8px',
          borderRadius: '50%',
          background: active ? 'unset' : lighten(0.1, Theme.PrimaryGrey)
        }}
      >
        {index}
      </span>{' '}
      {title}
    </Text>
  );
};

const SignUp = ({ onFinish, loading }) => {
  const [activeTab, setActiveTab] = useState(1);

  const [data, setData] = useState({})

  const nextStep = (data) => {
    setData(prev => ({ ...prev, ...data }));
    setActiveTab(2)
  }

  return (
    <Box pad="20px 0">
      <Box display="flex">
        <img alt="logo" src={LOGO} height="50px" style={{ margin: '0 auto' }} />
      </Box>

      <Box pad="15px 0" align="center">
        <Text size="24px" bold>
          School Signup
        </Text>
        <Spacer />
        <Text>Getting to know you and create your account.</Text>
      </Box>

      <Box pad="20px" align="center" background={lighten(0.2, Theme.PrimaryGrey)}>
        <TabTitle
          index={1}
          title="About the school"
          active={activeTab === 1}
          onClick={() => setActiveTab(1)}
        />
        <span> <i className="saf-arrow-right-3" style={{ padding: '0 10px' }} /> </span>
        <TabTitle
          index={2}
          title="About You"
          active={activeTab === 2}
        // onClick={() => setActiveTab(2)}
        />
      </Box>

      <Box pad="20px" display="flex">
        {activeTab === 1 && <Box margin="0 auto" style={{ maxWidth: '400px' }}>
          <Step1 nextStep={nextStep} />
        </Box>}
        {activeTab === 2 && <Box margin="0 auto" style={{ maxWidth: '400px' }}>
          <Step2 data={data} goBack={() => setActiveTab(1)} onFinish={onFinish} loading={loading} />
        </Box>}
      </Box>
    </Box>
  );
};

export default SignUp;
